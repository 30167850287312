import {
  Fieldset as HeadlessFieldset,
  Legend as HeadlessLegend,
  Field as HeadlessField,
  Label as HeadlessLabel,
  Description as HeadlessDescription,
} from "@headlessui/react";
import type {
  FieldsetProps as HeadlessFieldsetProps,
  LegendProps as HeadlessLegendProps,
  FieldProps as HeadlessFieldProps,
  LabelProps as HeadlessLabelProps,
  DescriptionProps as HeadlessDescriptionProps,
} from "@headlessui/react";
import { cx } from "@/lib/cva";
import type React from "react";

export function Fieldset({
  className,
  ...props
}: { className?: string } & Omit<HeadlessFieldsetProps, "as" | "className">) {
  return (
    <HeadlessFieldset
      {...props}
      className={cx(
        className,
        "[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1",
      )}
    />
  );
}

export function Legend({
  className,
  ...props
}: { className?: string } & Omit<HeadlessLegendProps, "as" | "className">) {
  return (
    <HeadlessLegend
      data-slot="legend"
      {...props}
      className={cx(
        className,
        "data-disabled:opacity-50 text-base/6 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white",
      )}
    />
  );
}

export function FieldGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      data-slot="control"
      {...props}
      className={cx(className, "space-y-8")}
    />
  );
}

export function Field({
  className,
  ...props
}: { className?: string } & Omit<HeadlessFieldProps, "as" | "className">) {
  return (
    <HeadlessField
      {...props}
      className={cx(
        className,
        "[&>[data-slot=label]+[data-slot=control]]:mt-1",
        "[&>[data-slot=label]+[data-slot=description]]:mt-1",
        "[&>[data-slot=description]+[data-slot=control]]:mt-3",
        "[&>[data-slot=control]+[data-slot=description]]:mt-3",
        "[&>[data-slot=control]+[data-slot=error]]:mt-2",
        "[&>[data-slot=label]]:font-semibold",
      )}
    />
  );
}

export function Label({
  className,
  isRequired = false,
  children,
  ...props
}: {
  className?: string;
  isRequired?: boolean;
  children: React.ReactNode;
} & Omit<HeadlessLabelProps, "as" | "className" | "children">) {
  return (
    <HeadlessLabel
      data-slot="label"
      aria-required={isRequired}
      {...props}
      className={cx(
        "data-disabled:opacity-50 select-none text-sm/5 font-semibold text-gray-900 dark:text-gray-100",
        className,
      )}
    >
      {children} {isRequired ? <span className="text-red-500">*</span> : null}
    </HeadlessLabel>
  );
}

export function Description({
  className,
  ...props
}: { className?: string } & Omit<
  HeadlessDescriptionProps,
  "as" | "className"
>) {
  return (
    <HeadlessDescription
      data-slot="description"
      {...props}
      className={cx(
        className,
        "text-muted-foreground data-disabled:opacity-50 text-sm dark:text-zinc-400",
      )}
    />
  );
}

export function ErrorMessage({
  className,
  ...props
}: { className?: string } & Omit<
  HeadlessDescriptionProps,
  "as" | "className"
>) {
  return (
    <HeadlessDescription
      data-slot="error"
      {...props}
      className={cx(
        "data-disabled:opacity-50 text-sm/normal text-red-500",
        className,
      )}
    />
  );
}

import { cva, type VariantProps } from "@/lib/cva";
import type { ComponentProps } from "react";

const badge = cva({
  base: "rounded-xs inline-flex select-none items-center gap-1 whitespace-nowrap font-bold uppercase tabular-nums",
  variants: {
    colorScheme: {
      blue: "bg-blue-50 text-blue-500",
      gray: "bg-gray-200 text-gray-800",
      green: "bg-green-100 text-green-800",
      magenta: "bg-magenta-50 text-magenta-700",
      red: "bg-red-100 text-red-700",
      teal: "bg-teal-50 text-teal-800",
      yellow: "bg-yellow-200 text-yellow-800",
    },
    size: {
      xs: "px-1 text-[0.625rem]/3",
      sm: "px-1.5 text-xs/4",
      md: "px-2 text-sm/5",
      lg: "px-2.5 text-sm/4",
      xl: "px-3 text-base",
    },
  },
  defaultVariants: {
    colorScheme: "blue",
    size: "md",
  },
});

export type BadgeProps = VariantProps<typeof badge> & ComponentProps<"div">;

export const Badge = ({
  colorScheme,
  className,
  children,
  size,
  ...props
}: BadgeProps) => {
  return (
    <div {...props} className={badge({ colorScheme, size, className })}>
      {children}
    </div>
  );
};

import { cx } from "@/lib/cva";
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faHourglass,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster as SonnerToaster } from "sonner";

export const Toaster = () => {
  return (
    <SonnerToaster
      position="bottom-left"
      expand
      visibleToasts={5}
      offset={{
        left: 20,
      }}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            "flex min-h-11 min-w-[22.5rem] justify-between rounded-sm bg-white p-0 text-left text-gray-800 shadow-md dark:bg-gray-800 dark:text-gray-200",
          icon: cx(
            "w-9! h-(--initial-height)! justify-center! rounded-bl-sm rounded-tl-sm text-white",
            "in-data-[type=success]:bg-green-400 in-data-[type=error]:bg-red-400 in-data-[type=loading]:bg-teal-400 in-data-[type=warning]:bg-amber-400 in-data-[type=info]:bg-blue-400",
            "dark:in-data-[type=success]:bg-green-600 dark:in-data-[type=success]:bg-red-600 dark:in-data-[type=success]:bg-teal-600 dark:in-data-[type=success]:bg-amber-600 dark:in-data-[type=success]:bg-blue-600",
          ),
          content: "w-full grow flex-wrap py-3 pl-4 pr-2",
          title:
            "m-0 w-full text-sm/5 font-semibold text-gray-900 dark:text-gray-100",
          description: "mt-1 text-xs/4 text-gray-700 dark:text-gray-300",
        },
      }}
      icons={{
        info: <FontAwesomeIcon icon={faCircleInfo} />,
        warning: <FontAwesomeIcon icon={faTriangleExclamation} />,
        loading: <FontAwesomeIcon icon={faHourglass} pulse />,
        error: <FontAwesomeIcon icon={faCircleExclamation} />,
        success: <FontAwesomeIcon icon={faCheckCircle} />,
      }}
    />
  );
};

import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { type ReactNode } from "react";
import { Badge } from "@/components/badge";
import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ErrorPage = ({
  badgeText,
  title,
  description,
  secondaryButton,
  primaryButton,
}: {
  badgeText?: string;
  title: string;
  description: string;
  secondaryButton?: ReactNode;
  primaryButton?: ReactNode;
}) => (
  <div className="min-w-dvw grid min-h-dvh place-content-center">
    <div className="space-y-4 px-4 text-center">
      {badgeText ? (
        <Badge size="xl" className="bg-gray-900 text-white">
          {badgeText}
        </Badge>
      ) : null}
      <h1 className="text-3xl/10 font-bold text-gray-900">{title}</h1>
      <div className="max-w-120">
        <Text>{description}</Text>
      </div>
      <div className="mt-2 space-y-2">
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
  </div>
);

export const NotFoundErrorPage = () => (
  <ErrorPage
    badgeText="404 not found"
    title="Did you take a wrong turn?"
    description="You might be lost; this page doesn't seem to exist."
    primaryButton={
      <Button
        onClick={() => window.history.back()}
        variant="outline"
        colorScheme="gray"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Go back
      </Button>
    }
  />
);

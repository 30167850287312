import { cx } from "@/lib/cva";
import StordEmblemAndLogo from "@/assets/stord-logo-with-emblem.svg?react";
import { Text } from "./text";

export const PoweredByStordFooter = ({
  divider = true,
  hidePrivacyPolicy = false,
  className,
}: {
  divider?: boolean;
  hidePrivacyPolicy?: boolean;
  className?: string;
}) => {
  return (
    <>
      {divider ? <hr className="mt-10 border-gray-200 lg:mt-6" /> : null}

      <div
        className={cx(
          "flex w-full flex-row items-center justify-end gap-2 py-3",
          className,
        )}
      >
        <Text asChild intent="caption">
          <a
            className="inline-flex hover:underline"
            href="https://www.stord.com/"
            target="_blank"
            rel="noreferrer"
          >
            Powered by&nbsp;
            <StordEmblemAndLogo width={56} />
          </a>
        </Text>
        {!hidePrivacyPolicy ? (
          <>
            <span className="text-xs/4 text-gray-700"> | </span>
            <Text asChild intent="caption">
              <a
                href="https://www.stord.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Text>
          </>
        ) : null}
      </div>
    </>
  );
};

import { DataInteractive } from "@headlessui/react";
import { Link as RouterLink, type LinkProps } from "react-router-dom";

export const Link = (
  props: {
    href: string | LinkProps["to"];
  } & Omit<LinkProps, "to">,
) => {
  return (
    <DataInteractive>
      <RouterLink {...props} to={props.href} />
    </DataInteractive>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./button";
import { Text } from "./text";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import StordEmblemAndLogo from "@/assets/stord-logo-with-emblem.svg?react";

export function ErrorBoundaryPage() {
  return (
    <div className="flex h-dvh place-content-center items-center justify-center bg-white bg-[url(/clouds.webp)] bg-cover px-4 dark:bg-black">
      <StordEmblemAndLogo className="w-45 absolute top-[min(8%,80px)] fill-black dark:fill-white" />
      <div className="max-w-120 space-y-4 text-center">
        <h1 className="text-3xl/10 font-bold text-gray-900">Hmmm...</h1>
        <Text>
          Our system seems to be having some trouble.{" "}
          <a
            className="text-blue-500 hover:underline"
            href="https://status.stord.com/"
          >
            Check our status page
          </a>{" "}
          to see if we&apos;re experiencing an outage or{" "}
          <a
            className="text-blue-500 hover:underline"
            href="https://helpdesk.stord.com/"
          >
            contact support
          </a>{" "}
          and we&apos;ll see how we can help.
        </Text>
        <Button onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faRefresh} />
          Refresh
        </Button>
      </div>
    </div>
  );
}

import { Link } from "./link";
import { cx, cva, type VariantProps } from "@/lib/cva";
import { Slot } from "@radix-ui/react-slot";

const text = cva({
  base: "text-sm/5 font-normal text-gray-800",
  variants: {
    intent: {
      title: "text-[1.375rem]/8 font-semibold text-gray-900",
      sectionTitle: "text-base/6 font-semibold text-gray-900",
      caption: "text-xs/4 font-normal text-gray-700 dark:text-gray-300",
      label: "text-sm/5 font-semibold text-gray-900 dark:text-gray-100",
      subtitle: "text-lg/6 font-semibold text-gray-900 dark:text-gray-100",
      tooltip: "text-xs/4 font-semibold text-gray-900 dark:text-gray-100",
    },
  },
});

interface TextProps
  extends React.ComponentPropsWithoutRef<"p">,
    VariantProps<typeof text> {
  asChild?: boolean;
}

export function Text({
  className,
  asChild = false,
  intent,
  ...props
}: TextProps) {
  const Component = asChild ? Slot : "p";

  return (
    <Component
      data-slot="text"
      {...props}
      className={text({ className, intent })}
    />
  );
}

export function TextLink({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className={cx(
        className,
        "data-hover:decoration-zinc-950 dark:data-hover:decoration-white text-zinc-950 underline decoration-zinc-950/50 dark:text-white dark:decoration-white/50",
      )}
    />
  );
}

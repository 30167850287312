import { ErrorBoundary, type ErrorBoundaryProps } from "react-error-boundary";

import { datadogRum } from "@datadog/browser-rum";
import { isPreloadError } from "@stordco/fe-components";
import { PageLoader } from "./page-loader";

import { ErrorBoundaryPage } from "./error-boundary-page";

export function DatadogErrorBoundary({
  onError,
  ...props
}: Omit<
  ErrorBoundaryProps,
  "fallbackRender" | "fallback" | "FallbackComponent"
>) {
  return (
    <ErrorBoundary
      onError={(error, info) => {
        onError?.(error, info);

        if (!isPreloadError(error)) {
          datadogRum.addError(error, {
            scope: "error-boundary",
          });
        }
      }}
      fallbackRender={({ error }) =>
        isPreloadError(error) ? <PageLoader /> : <ErrorBoundaryPage />
      }
      {...props}
    />
  );
}

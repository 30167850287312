import { lazy, Suspense, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  useRouteError,
  useLocation,
} from "react-router-dom";
import { NotFoundErrorPage } from "./ErrorPages";
import OrderSearch from "./features/search/OrderSearch";
import { usePostHog } from "posthog-js/react";

const ShipmentTracking = lazy(
  () => import("./features/tracking/ShipmentTracking"),
);
const OrderDetails = lazy(() => import("./features/tracking/OrderDetails"));
const NetworkOrderSearch = lazy(
  () => import("./features/search/NetworkOrderSearch"),
);

const ErrorElement = function ErrorElement() {
  throw useRouteError();
};

export const routes = createBrowserRouter(
  [
    {
      path: "/",
      errorElement: <ErrorElement />,
      element: (
        <Suspense fallback={null}>
          <PostHogPageView />
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: "orders/:orderId",
          element: (
            <Suspense fallback={null}>
              <Outlet />
            </Suspense>
          ),
          children: [
            {
              path: "tracking/:shipmentId",
              element: <ShipmentTracking />,
            },
            {
              path: "file-claim?",
              element: <OrderDetails />,
            },
          ],
        },
        {
          path: "/orders",
          element: <OrderSearch />,
        },
        {
          path: "/:networkId/orders",
          element: <NetworkOrderSearch />,
        },
        {
          path: "*",
          element: <NotFoundErrorPage />,
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
      // @ts-expect-error react-router-dom v6 types are not up to date. :shame:
      v7_startTransition: true,
    },
  },
);

function PostHogPageView() {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview", {
      $current_url: location.pathname + location.search,
    });
  }, [posthog, location]);

  return null;
}

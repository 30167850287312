import StordEmblemAndLogo from "@/assets/stord-logo-with-emblem.svg?react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PoweredByStordFooter } from "@/components/powered-by-stord-footer";
import { useAuthenticate, useGlobalOrderLookup } from "../tracking/queries";
import type { ViewOrderDetailsFields } from "../tracking/types";
import { Text } from "@/components/text";
import { toast } from "sonner";
import { Field, Label } from "@/components/fieldset";
import { Button } from "@/components/button";
import type { CSSProperties } from "react";

export default function OrderSearch() {
  const { handleSubmit, control } = useForm<ViewOrderDetailsFields>({
    defaultValues: { email: "", order_number: "" },
  });

  const { mutateAsync: globalOrderLookup, isPending: isPendingLookup } =
    useGlobalOrderLookup();
  const { mutateAsync: authenticate, isPending: isPendingAuthenticate } =
    useAuthenticate();

  const navigate = useNavigate();

  const onSubmit = async ({ email, order_number }: ViewOrderDetailsFields) => {
    try {
      const { order_id } = await globalOrderLookup({ email, order_number });

      await authenticate({ email, order_number, order_id });

      navigate(`/orders/${order_id}`);
    } catch (error) {
      toast.error("Invalid credentials", {
        description:
          "There was an error authenticating your order. Please check your email and order number, and try again.",
      });
    }
  };

  return (
    <div
      style={
        {
          "--bg-position":
            "calc(50% + 560px) 29rem, calc(50% - 640px) 24rem, calc(50% - 280px) 4.5rem",
        } as CSSProperties
      }
      className="bg-(position:--bg-position) min-h-dvh bg-gray-50 bg-[url(/cloud_1_lightmode.webp),url(/cloud_2_lightmode.webp),url(/cloud_3_lightmode.webp)] bg-[598px_325px,457px_332px,541px_472px] bg-no-repeat"
    >
      <div className="container mx-auto max-w-6xl pb-4 pt-8 lg:pb-10 lg:pt-16">
        <div className="flex flex-col items-center gap-8">
          <StordEmblemAndLogo height={40} />

          <div className="rounded-lg bg-white p-6 shadow-lg lg:p-12">
            <form
              onSubmit={handleSubmit(onSubmit)}
              id="view-order-details-form"
              noValidate
            >
              <div className="space-y-2 text-center">
                <h1 className="lg:text-5xl/14 text-3xl font-bold text-black">
                  Find your order
                </h1>
                <Text className="lg:text-lg">
                  Please enter your email and order number.
                </Text>
              </div>

              <div className="space-y-8 pt-4">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    validate: (value) =>
                      /^[^@]+@[^@]+$/.test(value) ||
                      "This field must be an email address.",
                  }}
                  render={({ field, fieldState }) => (
                    <Field>
                      <Label
                        isRequired
                        className="text-lg sm:text-lg md:text-lg lg:text-[22px]"
                      >
                        Email
                      </Label>

                      <input
                        aria-invalid={Boolean(fieldState.error)}
                        {...field}
                        className="aria-invalid:border-red-500 aria-invalid:outline-red-500 aria-invalid:focus-visible:ring-red-500 lg:h-17 mt-2 flex h-14 w-full min-w-0 rounded-md border border-black bg-transparent px-6 py-4 text-lg outline-blue-300/50 ring-blue-300/10 transition-[color,box-shadow] placeholder:text-gray-300 focus-visible:border-blue-300 focus-visible:outline-1 focus-visible:ring focus-visible:ring-blue-300 lg:rounded-lg lg:px-9 lg:py-5 lg:text-xl"
                        placeholder="eg: name@domain.com"
                        autoCorrect="off"
                      />

                      {fieldState.error ? (
                        <Text className="text-sm text-red-500 lg:text-base">
                          {fieldState.error?.message}
                        </Text>
                      ) : null}
                    </Field>
                  )}
                />
                <Controller
                  name="order_number"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Field>
                      <Label
                        isRequired
                        className="text-lg sm:text-lg md:text-lg lg:text-[22px]"
                      >
                        Order number
                      </Label>

                      <input
                        {...field}
                        aria-invalid={Boolean(fieldState.error)}
                        className="aria-invalid:border-red-500 aria-invalid:outline-red-500 aria-invalid:focus-visible:ring-red-500 lg:h-17 mt-2 flex h-14 w-full min-w-0 rounded-md border border-black bg-transparent px-6 py-4 text-lg outline-blue-300/50 ring-blue-300/10 transition-[color,box-shadow] placeholder:text-gray-300 focus-visible:border-blue-300 focus-visible:outline-1 focus-visible:ring focus-visible:ring-blue-300 lg:rounded-lg lg:px-9 lg:py-5 lg:text-xl"
                        placeholder="eg: ORDER123456"
                        autoCorrect="off"
                      />

                      {fieldState.error ? (
                        <Text className="text-sm text-red-500 lg:text-base">
                          {fieldState.error?.message}
                        </Text>
                      ) : null}

                      <Text className="mt-1 text-sm text-gray-700 lg:text-base">
                        Order number can be found in the order confirmation
                        email.
                      </Text>
                    </Field>
                  )}
                />

                <Button
                  className="h-13 lg:h-16.5 w-full rounded-md py-4 text-lg sm:text-lg md:text-lg lg:rounded-lg lg:py-5 lg:text-[22px]"
                  type="submit"
                  disabled={isPendingAuthenticate || isPendingLookup}
                >
                  View order details
                </Button>
              </div>
            </form>
          </div>

          <PoweredByStordFooter className="justify-center" divider={false} />
        </div>
      </div>
    </div>
  );
}

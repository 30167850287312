import type { Api, FetchBaseQueryArgs } from "@stordco/fe-components";
import { createApi } from "@stordco/fe-components";
import qs from "query-string";
import {
  type ReactNode,
  useRef,
  useMemo,
  createContext,
  useContext,
} from "react";

export type ApiProviderContextValue = {
  client: Api;
  headers: {
    get: (key: string) => string | undefined;
    getAll: () => Record<string, string>;
    set: (key: string, value: string) => void;
  };
};
export const ApiProviderContext = createContext<ApiProviderContextValue | null>(
  null,
);
export const ApiProvider = ({
  children,
  clientOverrides,
  baseUrl,
}: {
  children: ReactNode;
  clientOverrides?: FetchBaseQueryArgs;
  baseUrl: string;
}) => {
  const customHeadersRef = useRef<Record<string, string>>({});

  const client = useMemo(() => {
    const baseArgs: FetchBaseQueryArgs = {
      baseUrl,
      prepareHeaders: async (headers) => {
        Object.entries(customHeadersRef.current).forEach(([key, value]) => {
          headers.set(key, value);
        });

        return headers;
      },
      paramsSerializer: qs.stringify,
    };

    const client = createApi({ ...baseArgs, ...clientOverrides });

    return client;
  }, [baseUrl, clientOverrides]);

  return (
    <ApiProviderContext.Provider
      value={{
        client,
        headers: {
          get: (key) => customHeadersRef.current[key],
          getAll: () => customHeadersRef.current,
          set: (key, value) => {
            customHeadersRef.current[key] = value;
          },
        },
      }}
    >
      {/* NOTE: @msutkowski can't remember why this is here, but it might be needed */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {client ? children : null}
    </ApiProviderContext.Provider>
  );
};

const useApiContext = () => {
  const apiContext = useContext(ApiProviderContext);

  if (!apiContext) {
    throw new Error(
      "`useApiContext` can only be used inside an `<ApiProvider>`",
    );
  }

  return apiContext;
};

/**
 * @returns An API instance that automatically sets the correct headers
 */
export const useApi = () => useApiContext().client;

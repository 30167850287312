import type { ButtonProps as HeadlessButtonProps } from "@headlessui/react";
import { Button as HeadlessButton } from "@headlessui/react";
import { cva, type VariantProps } from "@/lib/cva";
import type React from "react";
import { Link } from "./link";

const button = cva({
  base: [
    // Base
    "relative isolate inline-flex items-center justify-center gap-x-2 rounded-lg border text-base/6 font-medium transition-all",
    // Sizing
    "px-[calc(--spacing(3)-1px)] py-[calc(--spacing(1.5)-1px)] text-sm/6",
    // Focus
    "focus:outline-hidden data-focus:outline data-focus:outline-2 data-focus:outline-offset-2 data-focus:outline-blue-500",
    // Disabled
    "data-disabled:opacity-50",
    // Icon
    "*:data-icon:text-(--btn-icon) forced-colors:data-hover:[--btn-icon:ButtonText] *:data-icon:-mx-0.5 *:data-icon:my-0.5 *:data-icon:size-5 *:data-icon:shrink-0 sm:*:data-icon:my-1 sm:*:data-icon:size-4 forced-colors:[--btn-icon:ButtonText]",
  ],
  variants: {
    variant: {
      solid: [
        // Optical border, implemented as the button background to avoid corner artifacts
        "bg-(--btn-border) border-transparent",
        // Dark mode: border is rendered on `after` so background is set to button background
        "dark:bg-(--btn-bg)",
        // Button background, implemented as foreground layer to stack on top of pseudo-border layer
        "before:bg-(--btn-bg) before:absolute before:inset-0 before:-z-10 before:rounded-[calc(var(--radius-lg)-1px)]",
        // Drop shadow, applied to the inset `before` layer so it blends with the border
        "before:shadow-sm",
        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
        "dark:before:hidden",
        // Dark mode: Subtle white outline is applied using a border
        "dark:border-white/5",
        // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
        "after:absolute after:inset-0 after:-z-10 after:rounded-[calc(var(--radius-lg)-1px)]",
        // Inner highlight shadow
        "after:shadow-[shadow:inset_0_1px_--theme(--color-white/15%)]",
        // White overlay on hover
        "data-active:after:bg-(--btn-hover-overlay) data-hover:after:bg-(--btn-hover-overlay)",
        // Dark mode: `after` layer expands to cover entire button
        "dark:after:-inset-px dark:after:rounded-lg",
        // Disabled
        "data-disabled:before:shadow-none data-disabled:after:shadow-none",
      ],
      outline: [
        // Base
        "data-active:bg-zinc-950/2.5 data-hover:bg-zinc-950/2.5 border-zinc-950/10 text-zinc-950",
        // Dark mode
        "dark:(--btn-bg:transparent) dark:data-active:bg-white/5 dark:data-hover:bg-white/5 dark:border-white/15 dark:text-white",
      ],
      ghost: ["border-none hover:bg-gray-100"],
    },
    colorScheme: {
      blue: [
        "[--btn-border:var(--color-blue-500)]/90 [--btn-hover-overlay:var(--color-black)]/20 text-white [--btn-bg:var(--color-blue-500)]",
        "data-active:[--btn-icon:var(--color-blue-300)] data-hover:[--btn-icon:var(--color-white)] [--btn-icon:var(--color-white)]",
      ],
      gray: [
        "[--btn-border:var(--color-gray-700)]/90 [--btn-hover-overlay:var(--color-white)]/10 text-gray-800 [--btn-bg:var(--color-gray-600)]",
        "[--btn-icon:var(--color-gray-800)]",
      ],
    },
  },
  compoundVariants: [
    // You should not be using this compound variant
    {
      variant: "outline",
      colorScheme: "blue",
      className: "border-red-500",
    },
  ],
  defaultVariants: {
    colorScheme: "blue",
    variant: "solid",
  },
});

export type ButtonProps = VariantProps<typeof button> & {
  className?: string;
  children: React.ReactNode;
} & (
    | Omit<HeadlessButtonProps, "as" | "className">
    | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className">
  );

export const Button = ({
  children,
  variant,
  colorScheme,
  className,
  ...props
}: ButtonProps) => {
  const classes = button({
    variant,
    colorScheme,
    className,
  });

  return "href" in props ? (
    <Link {...props} className={classes}>
      <TouchTarget>{children}</TouchTarget>
    </Link>
  ) : (
    <HeadlessButton {...props} className={classes}>
      <TouchTarget>{children}</TouchTarget>
    </HeadlessButton>
  );
};

/**
 * Expand the hit area to at least 44×44px on touch devices
 */
export function TouchTarget({ children }: { children: React.ReactNode }) {
  return (
    <>
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
      />
      {children}
    </>
  );
}

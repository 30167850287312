import "@total-typescript/ts-reset";
import { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ConfigProvider,
  DatadogProvider,
  attachPreloadErrorHandler,
} from "@stordco/fe-components";

import { ApiProvider } from "./ApiProvider";
import App from "./App";
import { PostHogProvider } from "posthog-js/react";
import { Toaster } from "@/components/toaster";
import { DatadogErrorBoundary } from "@/components/datadog-error-boundary";
import { PageLoader } from "@/components/page-loader";

attachPreloadErrorHandler();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <DatadogErrorBoundary>
      <Suspense fallback={<PageLoader />}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools position="bottom" buttonPosition="bottom-right" />
          <ConfigProvider>
            {(config) => (
              <DatadogProvider
                version={__APP_VERSION__}
                applicationId={config.DATADOG_APPLICATION_ID}
                clientToken={config.DATADOG_CLIENT_TOKEN}
                service={config.DATADOG_SERVICE_NAME}
                env={config.DATADOG_ENV}
              >
                <PostHogProvider
                  apiKey={config.POSTHOG_API_KEY}
                  options={{
                    capture_pageview: false,
                  }}
                >
                  <ApiProvider baseUrl={config.API_URL}>
                    <App />
                    <Toaster />
                  </ApiProvider>
                </PostHogProvider>
              </DatadogProvider>
            )}
          </ConfigProvider>
        </QueryClientProvider>
      </Suspense>
    </DatadogErrorBoundary>
  </StrictMode>,
);
